﻿/**
 * 常用样式
 */
/*  */

/* 单行文字省略 */
@mixin ell() {
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* display:inline-block;IE6,7块级元素对inline-block支持不好 */
@mixin dib() {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

/* 清除浮动 */
@mixin clearfix {
  &:after {
    clear: both;
    content: '.';
    display: block;
    height: 0;
    line-height: 0;
    overflow: hidden;
  }
  *height: 1%;
}


/* 箭头 arrow(direction, size, color); */
@mixin arrow($direction, $size, $color) {
  width: 0;
  height: 0;
  line-height: 0;
  font-size: 0;
  overflow: hidden;
  border-width: $size;
  cursor: pointer;
  @if $direction == top {
    border-style: dashed dashed solid dashed;
    border-color: transparent transparent $color transparent;
    border-top: none;
  }
  @else if $direction == bottom {
    border-style: solid dashed dashed dashed;
    border-color: $color transparent transparent transparent;
    border-bottom: none;
  }
  @else if $direction == right {
    border-style: dashed dashed dashed solid;
    border-color: transparent transparent transparent $color;
    border-right: none;
  }
  @else if $direction == left {
    border-style: dashed solid dashed dashed;
    border-color: transparent $color transparent transparent;
    border-left: none;
  }
}


/* 透明 */
@mixin opacity($opacity){
  opacity:#{$opacity};
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$opacity*100})";
  filter: alpha(opacity=#{$opacity*100});
}

