@import './utils';
.common_bg{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 909px;
	z-index: -1;
	overflow: hidden;
	.wrap{
		position: relative;
		margin: auto;
		width: 1200px;
		height: 0;
		overflow: visible;
		.bg{
			position: absolute;
			top: 0;
			left: -360px;
			width: 1920px;
			height: 909px;
			// background-image: url('/pc/img/bg_1.png');
			background-repeat: no-repeat;
			z-index: -1;
		}
	}
}
.yinCang {
  display: none;
}
header{
	.head_hd{
    height: 40px;
		line-height: 40px;
		.head_hd_wrap{
			margin: auto;
			width: 1200px;
			height: 40px;
			line-height: 40px;
			font-size: 12px;
			color: #666;
			.left{
				float: left;
			}
			.right{
				float: right;
				/* .date{
					float: left;
				}
				.login{
					float: right;
				}
				.user{
					float: right;
					margin-left: 20px;
				} */
			}
		}
	}

	.head_bd{
    background-position: center;
    background-image: url('/pc/img/bg_02.jpg');
    padding-bottom: 22px;
		margin: auto;
		width: 1200px;
		@include clearfix;
		.operations{
			.left{
				float: left;
				.logo{
					margin-top: 25px;
				}
				.tol{
					margin-bottom: 10px;
    			margin-left: 60px;
				}
			}
			.right{
				float: right;
				.info{
					margin-top: 28px;
					height: 16px;
					line-height: 16px;
					.btn_login{
						float: right;
						font-size: 14px;
						color: #015293;
						cursor: pointer;
						&:hover{
							color: #2F72A7;
						}
					}
					.user{
						float: right;
					}
					.delimiter{
						float: right;
						width: 1px;
						height: 100%;
						margin: 0 18px;
						background-color: #c9c9c9;
					}
					.time{
						float: right;
						font-size: 12px;
						// color: #666;
					}
				}
				.search{
					margin-top: 18px;
					input{
						float: left;
						width: 241px;
						height: 33px;
						padding: 0 12px;
						border: 1px solid #74a0c4;
						font-size: 12px;
						color: #333;
					}
					.btn{
						float: left;
						width: 59px;
						height: 35px;
						line-height: 35px;
						font-size: 14px;
						text-align: center;
						color: #fff;
						background-color: #015293;
						cursor: pointer;
						&:hover{
							background-color: #3376AC;
						};
					}
				}
			}
			
		}
		
    
	}
  .nav_head {
    background-color: #015293;
  }
  nav{
		margin: auto;
		width: 1200px;
    // margin-top: 22px;
    ul.navMenu{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      // border-bottom: 4px solid #015293;
      li.navMenuItem{
        position: relative;
        float: left;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        font-weight: bold;
        // margin-left: 30px;
        margin-left: 19px;
        margin-bottom: 6px;
        margin-top: 3px;
        text-align: center;
        >a{
          /* color: #4f4f4f; */
          // color: #015293;
          color: #fff;
          .iconfont{
            display: inline;
            font-size: 18px;
            margin-right: 4px;
          }
          
          .navicon{
            display: none;
          }
        }
        &:first-child{
          margin-left: 0;
        }
        &:hover{
          >a{
            // color: #015293;
            color: #fff;
          }
          >.dropMenu_wrap{
            display: block;
          }
        }
        .dropMenu_wrap{
          display: none;
          position: absolute;
          top: -3px;
          left: 100%;
          z-index: 10;
          .dropMenu{
            border-top: 2px solid #015293;
            background-color: #fff;
            box-shadow:-3px 7px 10px #666666;
            border-bottom-right-radius:3px;
            border-bottom-left-radius:3px;
            .dropMenuItem{
              position: relative;
              height: 40px;
              line-height: 40px;
              min-width: 162px;
              max-width: 198px;
              font-size: 14px;
              border-top: 1px solid #ececec;
              text-align: left;
              overflow: visible;
              &:first-child{
                border: 0;
              }
              >.txt{
                @include ell();
                a{
                  padding: 0 24px;
                  color: #666;
                }
              }
              .iconfont{
                position: absolute;
                top: 0;
                right: 10px;
                display: inline;
              }
              .dropMenu_wrap{
                display: none;
              }
              &:hover{
                >.txt{
                  a{
                    color: #015293;
                  }
                }
                .iconfont{
                  color: #015293;
                }
                background-color: #ececec;
                >.dropMenu_wrap{
                  display: block;
                }
              }
            }
          }
        }
        .level_top{
          top: 100%;
          left: 0;
          padding-top: 5px;
        }
      }
      li.hov{
        >a{
          // color: #015293;
        }
      }
    }
  }
}