@import './utils';
// 黑白背景
// html { filter:progid:DXImageTransform.Microsoft.BasicImage(grayscale=1)}  
// body{
//     min-width: 1200px;
//     -webkit-filter: grayscale(100%);
//     -moz-filter: grayscale(100%);
//     -ms-filter: grayscale(100%);
//     -o-filter: grayscale(100%);
//     -webkit-filter: gray;
//     filter: grayscale(100%);
//     filter: gray;
//     filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
//     filter: grayscale(100%); 
// }
// *{
//     -moz-filter: grayscale(100%); 
//     -ms-filter: grayscale(100%); 
//     -o-filter: grayscale(100%); 
//     filter: gray;
// }


body{
	min-width: 1200px;
}
header,footer{
	// max-width: 1920px;
	min-width: 1200px;
	margin: auto;
}
.cf{
	@include clearfix;
}

input:-webkit-autofill , textarea:-webkit-autofill, select:-webkit-autofill {
    -webkit-text-fill-color: #646464 !important;
    -webkit-box-shadow: 0 0 0px 1000px transparent  inset !important;
    background-color:transparent !important;
    background-image: none !important;
    transition: background-color 50000s ease-in-out 0s; //背景色透明  生效时长  过渡效果  启用时延迟的时间
}


a:hover{
	text-decoration: none;
}

.ell{
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}