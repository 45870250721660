@import './utils';
footer{
	height: 214px;
	text-align: center;
	color: #fff;
	background-color: #015293;
	.footer_wrap{
		position: relative;
		width: 1200px;
		margin: auto;
		overflow: hidden;
		.title{
			margin-top: 46px;
			height: 32px;
			line-height: 32px;
			font-size: 22px;
		}
		.address{
			margin-top: 25px;
			height: 26px;
			line-height: 26px;
			font-size: 16px;
		}
		.copyright{
			margin-top: 4px;
			height: 26px;
			line-height: 26px;
			font-size: 16px;
			a{
				color: #fff;
			}
		}
		.logo{
			position: absolute;
			top: 58px;
			right: 200px;
		}
	}
}