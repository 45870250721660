@import './mod/reset';
@import './mod/common';
@import './mod/utils';
@import './mod/head';
@import './mod/foot';
@import './mod/rSide';
.jquery-slider-selectors {
    bottom: 10px;
    right: 20px;
    left: auto;
}

.jquery-slider-selector {
    font-size: 14px;
    width: 20px;
    height: 20px;
    margin: 0 0 0 5px;
    border-radius: 0;
    background: #000;
    color: #fff;
    text-align: center;
}

.jquery-slider-selector-current {
    background: #fff;
    color: #999;
}

.jquery-slider-selector span {
    display: block;
}
.lunbo {

}
.index {
    position: relative;
    margin: auto;
    margin-top: 20px;
    width: 1200px;
    padding-bottom: 50px;
    overflow: visible;
    // padding: 0 20px 15px;
    background-color: #fff;
    .idx_row {
        .left {
            float: left;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .newsImg {
              img {
                width: 330px;
              }
              p {
                text-align: center;
                line-height: 40px;
              }
            }
            .funcs_wheel {
                /*position: relative;
                padding-left: 118px;
                 .funcs{
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 3;
                    .func{
                        position: relative;
                        display: block;
                        width: 112px;
                        height: 82px;
                        background-image: url(/pc/img/index/icons.png);
                        background-color: #015293;
                        margin-bottom: 6px;
                        .wrap{
                            display: none;
                            position: absolute;
                            top: 17px;
                            left: 100%;
                            height: 48px;
                            line-height: 48px;
                        }
                        .txt{
                            position: relative;
                            margin-left: 10px;
                            padding: 0 10px;
                            background-color: #015293;
                            color: #fff;
                            white-space: nowrap;
                            &:before{
                                position: absolute;
                                left: -10px;
                                top: 13px;
                                display: block;
                                content: ' ';
                                width:0;
                                height:0;
                                border-top:10px solid transparent;
                                border-bottom:10px solid transparent;
                                border-right:10px solid #015293;
                            }
                        }
                        ._email{
                            
                        }
                        ._share{
                            .social-share-icon{
                                width: 25px;
                                height: 25px;
                                line-height: 25px;
                                font-size: 14px;
                            }
                        }
                        ._tel{
                            
                        }
                    }
                    .download{
                        background-position: 0 0;
                    }
                    .zzzx_email{
                        background-position: -112px 0;
                    }
                    .zzzx_share{
                        background-position: -224px 0;
                    }
                    .zzzx_tel{
                        background-position: -336px 0;
                    }
                } */
                .wheel {
                    width: 100%;
                    height: 336px;
                    overflow: hidden;
                    div {
                        a {
                            display: block;
                            font-size: 0;
                            img {
                              width: 100%;
                              // height: 336px;
                            }
                        }
                        .explain {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 40px;
                            line-height: 40px;
                            text-align: center;
                            .bg {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                background-color: #000;
                                opacity: .7;
                            }
                            .txt {
                                position: relative;
                                font-size: 14px;
                                color: #fff;
                                z-index: 1;
                            }
                        }
                    }
                }
            }
        }
        .anniversary {
            width: 100%;
        }
        .right {
            float: right;
        }
    }
    .tuPian {
      margin: 40px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .mt {
        margin-top: 15px !important;
    }
    .module {
        display: block;
        .hd {
            position: relative;
            ul {
                border-bottom: 1px solid #015293;
                li {
                    float: left;
                    width: 67px;
                    height: 34px;
                    line-height: 34px;
                    font-size: 14px;
                    padding: 0 5px;
                    border-right: 1px solid transparent;
                    border-left: 1px solid transparent;
                    cursor: pointer;
                    text-align: center;
                    a {
                        color: #015293;
                    }
                    &:hover {
                        border-right: 1px solid #3d7bac;
                        border-left: 1px solid #3d7bac;
                        background-color: #015293;
                        a {
                            color: #fff;
                        }
                    }
                }
                .hov {
                    border-right: 1px solid #3d7bac;
                    border-left: 1px solid #3d7bac;
                    background-color: #015293;
                    border-bottom: 3px solid #015293;
                    a {
                        color: #fff;
                    }
                }
            }
            .more {
                position: absolute;
                right: 0;
                top: 10px;
                font-size: 12px;
                color: #666;
                .iconfont {
                    display: inline;
                    font-size: 12px;
                    margin-left: 1px;
                }
                &:hover {
                    color: #015293;
                }
            }
        }
        .bd {
            margin-top: 15px;
            overflow: hidden;
            ul {
                li {
                    padding-right: 73px;
                    height: 29px;
                    line-height: 29px;
                    a {
                        color: #666;
                        .txt {
                            width: 100%;
                            font-size: 14px;
                            @include ell;
                            .dian {
                                margin-right: 5px;
                            }
                        }
                        .time {
                            float: right;
                            margin-right: -73px;
                            width: 73px;
                            font-size: 12px;
                            text-align: right;
                        }
                        &:hover {
                            color: #015293;
                            .txt,
                            .time {
                                //text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }
    .module1 {
        display: block;
        .hd {
            position: relative;
            height: 55px;
            border-bottom: 1px solid #015293;
            overflow: hidden;
            .news_title {
              border-bottom: 3px solid #015293;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
            }
            .icon {
                position: absolute;
                top: 11px;
                left: 0;
                width: 9px;
                height: 17px;
                background-image: url(/pc/img/index/icons.png);
                background-position: 0 -180px;
            }
            ul {
                // margin-top: 23px;
                li {
                    float: left;
                    height: 50px;
                    line-height: 35px;
                    font-size: 20px;
                    font-weight: bold;
                    color: #015293;
                    a {
                        display: block;
                        font-size: 16px;
                        padding: 0 9px;
                        color: #666;
                        border-right: 1px solid #fff;
                        border-left: 1px solid #fff;
                        cursor: pointer;
                        &:hover {
                            color: #fff;
                            background-color: #015293;
                            border-right: 1px solid #3d7bac;
                            border-left: 1px solid #3d7bac;
                        }
                    }
                    &:first-child {
                        line-height: 35px;
                        margin: 0 7px 0 14px;
                    }
                }
                .hov {
                  border-bottom: 3px solid #015293;
                    a {
                        color: #fff;
                        background-color: #015293;
                        // border-right: 1px solid #3d7bac;
                        // border-left: 1px solid #3d7bac;
                    }
                }
                .enhancefont {
                    a {
                        font-size: 20px;
                        // width: 90px;
                        align-items: center;
                    }
                }
            }
            .culture_navs {
                li {
                    margin-left: 0;
                }
            }
            .more {
                font-size: 12px;
                color: #666;
                .iconfont {
                    display: inline;
                    font-size: 12px;
                    margin-left: 1px;
                }
                &:hover {
                    color: #015293;
                }
            }
        }
        .bd {
            margin-top: 15px;
            .left {
              .txtLeft {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 320px !important;
              }
            }
            .items {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
                .item {
                    float: left;
                    width: 300px;
                    height: 30px;
                    line-height: 30px;
                    font-size: 16px;
                    color: #656565;
                    // margin-right: 20px;
                    @include ell();
                    &:hover {
                        color: #015293;
                    }
                }
            }
            ul {
                min-height: 200px;
                li {
                    // padding: 14px 0 10px;
                    // height: 33px;
                    line-height: 33px;
                    a {
                        color: #666;
                        .txttitle {
                          display: flex;
                          flex-direction: row;
                          align-items: flex-start;
                          font-size: 16px;
                          .dian {
                            margin-right: 5px;
                          }
                        }
                        .txt {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            font-size: 16px;
                            @include ell;
                            /* 置顶 */
                            .topTag {
                                padding: 1px 4px;
                                margin-right: 5px;
                                color: #fff;
                                border: 1px solid #3882f0;
                                background-color: #66a3ff;
                                font-size: 12px;
                            }
                            .top {
                                color: #b70707;
                            }
                            /* 普通  */
                            .dian {
                                margin-right: 5px;
                            }
                        }
                        .time {
                            float: right;
                            margin-right: -80px;
                            width: 79px;
                            font-size: 14px;
                            text-align: right;
                        }
                        &:hover {
                            color: #015293;
                            .txt,
                            .time {
                                //text-decoration: underline;
                            }
                        }
                    }
                }
            }
            .org_intro {
                li {
                    height: auto;
                }
            }
            /**
             * 历史革沿
             */
            .his_evol {
                .his_evol_text {
                    font-size: 14px;
                    line-height: 30px;
                    color: #666;
                }
                .his_evol_hd {
                    position: relative;
                    width: 100%;
                    height: 41px;
                    line-height: 41px;
                    overflow: hidden;
                    .his_evol_nav {
                        position: absolute;
                        top: 0;
                        left: 0;
                        display: inline-block;
                        height: 20px;
                        padding: 0 28px 0 10px;
                        overflow: visible;
                        border-bottom: 1px solid #d9d9d9;
                        white-space: nowrap;
                        .his_evol_nav_item {
                            display: inline-block;
                            height: 21px;
                            line-height: 21px;
                            margin-left: 18px;
                            padding: 0 5px;
                            font-size: 14px;
                            color: #fff;
                            background-color: #d9d9d9;
                            text-align: center;
                            border-radius: 3px;
                            white-space: nowrap;
                            cursor: pointer;
                            .unit_year {
                                display: none;
                            }
                            &:hover {
                                width: 58px;
                                height: 36px;
                                line-height: 36px;
                                background-color: #015293;
                                span {
                                    display: inline;
                                }
                            }
                        }
                        .hov {
                            width: 58px;
                            height: 36px;
                            line-height: 36px;
                            background-color: #015293;
                            span.unit_year {
                                display: inline;
                            }
                        }
                    }
                    .btn {
                        position: absolute;
                        top: 0;
                        width: 16px;
                        height: 100%;
                        line-height: 37px;
                        color: #fff;
                        background-color: #d9d9d9;
                        text-align: center;
                        cursor: pointer;
                        &:hover {
                            background-color: #015293;
                        }
                    }
                    .btn_prev {
                        left: 0;
                    }
                    .btn_next {
                        right: 0;
                    }
                }
                .his_evol_bd {
                    margin-top: 20px;
                    min-height: 140px;
                    .his_evol_bitem {
                        display: none;
                        font-size: 12px;
                        line-height: 20px;
                        color: #666;
                        p {
                            text-indent: 2em;
                        }
                    }
                }
            }
        }
    }
    .wx_code {
        width: 100%;
        margin-top: 18px;
    }
}

// 党建要闻
.module1 {
  display: block;
  .hd {
      height: 42px;
      line-height: 42px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .footprint {
        width: 177px !important;
      }
      .titleTop {
          width: 120px;
          height: 39px;
          padding-left: 10px;
          display: inline-block;
          font-weight: 700;
          color: #a21215;
          font-size: 16px;
          // float: left;
          border-bottom: 3px solid #a21215;
          .title {
            padding-left: 10px;
            font-family: 黑体;
          }
      }
      .titleTop_study {
          width: 120px;
          height: 39px;
          padding-left: 10px;
          display: inline-block;
          font-weight: 700;
          color: #a21215;
          font-size: 16px;
          // float: left;
          border-bottom: 3px solid #a21215;
      }
      .moreFat {
          position: absolute;
          display: inline-block;
          height: 42px;
          line-height: 42px;
          right: 0;
          font-size: 12px;
          color: #666;
          cursor: pointer;
          .iconfont {
              display: inline-block;
              font-size: 12px;
              margin-left: 1px;
          }
          &:hover {
              color: #a21215;
          }
          .letter {
              font-size: 12px;
              color: #666;
          }
      }
      .kill_poor_title {
          display: block;
          margin: auto;
          margin-top: 17px;
          margin-bottom: 16px;
      }
      .icon {
          position: absolute;
          top: 11px;
          left: 0;
          width: 9px;
          height: 17px;
          background-image: url(/pc/img/topic/icons.png);
          background-position: 0 -180px;
      }
      ul {
          // margin-top: 23px;
          li {
              float: left;
              padding: 0 10px;
              height: 30px;
              line-height: 30px;
              font-size: 20px;
              margin-left: 7px;
              color: #bc1919;
              a {
                  display: block;
                  font-size: 16px;
                  padding: 0 9px;
                  color: #000;
                  border-right: 1px solid #fff;
                  border-left: 1px solid #fff;
                  cursor: pointer;
                  &:hover {
                      color: #fff;
                      background-color: #bc1919;
                      border-right: 1px solid #D85656;
                      border-left: 1px solid #D85656;
                  }
              }
              &:first-child {
                  line-height: 35px;
                  margin: 0 7px 0 14px;
              }
          }
          .hov {
            border-bottom: 3px solid #015293;
            a {
                  color: #fff;
                  background-color: #bc1919;
                  border-right: 1px solid #D85656;
                  border-left: 1px solid #D85656;
              }
          }
      }
      .more {
          font-size: 12px;
          color: #666;
          .iconfont {
              display: inline-block;
              font-size: 12px;
              margin-left: 1px;
          }
          &:hover {
              color: #015293;
          }
      }
  }
  // 我写的
  .bd {
      margin-top: 15px;
      a {
        color: #666;
      }
      .left {
        width: 720px;
        margin-top: 17px;
        ul {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            overflow: hidden;
            width: 720px;
            height: 535px;
          li {
            // width: 325px;
            height: 455px;
            &:nth-child(1) {
              margin-right: 70px;
            }
            a {
              color: #333;
              img {
                width: 325px;
                height: 250px;
              }
              .title {
                width: 320px;
                // height: 52px;
                font-size: 16px;
                line-height: 26px;
                font-weight: bold;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /* 这里是超出几行省略 */
                overflow: hidden;
                margin-top: 15px;
                font-family: 黑体;
                position: relative;
              }
            //   .title::after {
            //     content:"...";
            //     font-weight:bold;
            //     position:absolute;
            //     bottom:0;
            //     right:0;
            //     padding:0 20px 1px 45px;
                
            //     /* 为了展示效果更好 */
            //     background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(white), color-stop(50%, white));
            //     background: -moz-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
            //     background: -o-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
            //     background: -ms-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
            //     background: linear-gradient(to right, rgba(255, 255, 255, 0), white 80%, white);
            // }
              .time {
                line-height: 35px;
                color: #999;
                font-size: 14px;
              }
              .content {
                width: 100%;
                line-height: 24px;
                color: #999;
                font-size: 14px;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3; /* 这里是超出几行省略 */
                overflow: hidden;
              }
            }
          }
        }
      }
        // 党建要闻
        .right {
          width: 430px;
          ul {
            min-height: 264px;
            li {
                // padding-right: 80px;
                padding: 5px 0;
                // padding-right: 0px;
                font-size: 16px;
                border-bottom: 1px solid #f0f0f0;
                a {
                    color: #666;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .txt {
                        float: left;
                        width: 100%;
                        font-size: 16px;
                        text-overflow: ellipsis;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        @include ell;
                        /* 置顶 */
                        .topTag {
                            padding: 1px 4px;
                            margin-right: 5px;
                            color: #fff;
                            border: 1px solid #3882f0;
                            background-color: #66a3ff;
                            font-size: 12px;
                        }
                        .top {
                            color: #b70707;
                        }
                        .title {
                          color: #333;
                          font-size: 16px;
                          font-weight: bold;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          margin-left: 5px;
                          font-family: 黑体;
                        }
                        .frame {
                          border-left: 3px solid #015293;
                          height: 15px;
                          width: 3px;
                        }
                    }
                    .content {
                      // width: 100%;
                      height:30px;
                      word-break: break-all;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                      -webkit-line-clamp: 1; /* 这里是超出几行省略 */
                      overflow: hidden;
                      margin-left: 10px;
                      font-size: 14px;
                      line-height: 30px;
                      color: #999;
                    }
                    .time {
                        margin-right: 10px;
                        width: 100px;
                        font-size: 16px;
                    }
                    &:hover {
                        color: #015293;
                        .txt,
                        .time {
                            //text-decoration: underline;
                        }
                    }
                }
            }
        }
      }
  }
}


#qiehuan {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .title {
    /* float: left; */
    height: 50px;
    line-height: 26px;
    font-size: 20px;
    font-weight: bold;
    margin-left: 7px;
    // border-bottom: 3px solid #015293;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    font-family: 黑体;
    img {
      margin-top: 5px;
    }
  }
}
.wenZhang {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .txtContent {
    width: 100%;
    font-size: 16px;
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 黑体;
    .dian {
      margin-right: 5px;
    }
  }
  .conTime {
    width: 117px;
    font-size: 14px;
    text-align: right;
  }
}
#aa {
  padding-left: 0;
}

.obviousTitle {
  li {
    &:nth-child(1) {
      margin-bottom: 10px;
      .txtContent {
        font-size: 16px;
        color: #333;
        font-weight: bold;
        .dian {
          display: none;
        }
      }
      .conTime {
        display: none;
      }
    }
  }
}