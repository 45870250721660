.rSide {
    position: absolute;
    right: -92px;
    top: 0;
    bottom: auto;
    inset: 104.5px auto auto 1578.5px !important;
    .btns {
        float: left;
        .btn {
            position: relative;
            bottom: 200px;
            display: block;
            width: 65px;
            height: 65px;
            margin-bottom: 8px;
            color: #fff;
            background-color: #015293;
            cursor: pointer;
            overflow: visible;

            .icon {
                background-image: url(/pc/img/rSide/icons.png);
            }

            .xzzx {
                width: 21px;
                height: 20px;
                margin: 6px auto 7px;
                padding-top: 15px;
                background-position: 0 15px;
            }
            
            
            .jjsjxx {
                width: 27px;
                height: 20px;
                margin: 7px auto 3px;
                background-position: 0 -30px;
            }

            .contactUs{
                width: 24px;
                height: 24px;
                margin: 0 auto 7px;
                padding-top: 10px;
                background-position: 0 -80px;
            }
            .share{
                width: 24px;
                height: 27px;
                margin: 0 auto 7px;
                padding-top: 6px;
                background-position: 0 -114px;
            }

            .backtop {
                width: 24px;
                height: 14px;
                margin: 25px auto 0;
                background-position: 0 -60px;
            }

            .txt {
                line-height: 15px;
                font-size: 12px;
                text-align: center;
            }


            .wrap{
                display: none;
                position: absolute;
                top: 9px;
                right: 100%;
                height: 48px;
                line-height: 48px;
                ._share{
                    .social-share-icon{
                        width: 25px;
                        height: 25px;
                        line-height: 25px;
                        font-size: 14px;
                    }
                }
                .wrap_txt{
                    position: relative;
                    margin-right: 10px;
                    padding: 0 10px;
                    background-color: #015293;
                    color: #fff;
                    white-space: nowrap;
                    &:before{
                        position: absolute;
                        right: -10px;
                        top: 13px;
                        display: block;
                        content: ' ';
                        width:0;
                        height:0;
                        border-top:10px solid transparent;
                        border-bottom:10px solid transparent;
                        border-left:10px solid #015293;
                    }
                }
            }
        }
    }
}
/* 
.rSide-fixed {
    width: 150px;
    margin-top: 0;
    position: fixed;
    right: -92px;
    bottom: 0;
} 

.rSide-bottom {
    position: absolute;
    top: auto;
    bottom: 0;
}*/