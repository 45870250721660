﻿body,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,input,textarea,p,blockquote,th,td,hr,button,article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section {
  margin: 0;
  padding: 0;
  font-family: Microsoft Yahei , arial;
}


article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section {
  display: block;
}

audio,canvas,video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

input,select,textarea{
  font-size: 100%;
}

table {
  border: {
    collapse: collapse;
    spacing: 0;
  }
}

th {
  text-align: inherit;
}

fieldset,img {
  border: none;
}
img{
  border: none;
}

iframe {
  display: block;
}

abbr,acronym {
  border: 0;
  font-variant: normal;
}

del {
  text-decoration: line-through;
}

address,caption,cite,code,dfn,em,th,var {
  font: {
    style: normal;
    weight: 500;
  }
}

ol,ul,li {
  list-style: none;
}

caption,th {
  text-align: left;
}

h1,h2,h3,h4,h5,h6 {
  font: {
    size: 100%;
    weight: 500;
  }
}

q {
  &:before, &:after {
    content: '';
  }
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}


ins,a {
  text-decoration: none;
}

.txt-r{
  text-align:right;
}
.txt-c{
  text-align:center;
}

textarea{
  resize:none; overflow:auto;
}
input,textarea{ outline:none;}

a{color:#0081cb;outline:none; }